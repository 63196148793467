import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

const Device1 = React.lazy(() => import('../devices/Device1'));
const Device2 = React.lazy(() => import('../devices/Device2'));
const Device3 = React.lazy(() => import('../devices/Device3'));
const Device4 = React.lazy(() => import('../devices/Device4'));
const Device5 = React.lazy(() => import('../devices/Device5'));
const DeviceSelection = React.lazy(() => import('../devices/DeviceSelection'));
const PageNotFoundPage = React.lazy(() => import('../views/PageNotFoundView'));

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Outlet />}>
                <Route path="/" element={<DeviceSelection />} />
                <Route path="tv/1" element={<Device1 />} />
                <Route path="tv/2" element={<Device2 />} />
                <Route path="tv/3" element={<Device3 />} />
                <Route path="tv/4" element={<Device4 />} />
                <Route path="tv/5" element={<Device5 />} />
            </Route>
            <Route path="*" element={<PageNotFoundPage />} />
        </Routes>
    );
};

export default AppRoutes;
