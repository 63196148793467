import { Image } from '@mantine/core';
import laylaFoodLogo from '../assets/logo.png';

interface LogoProps {
    small?: boolean;
}

const Logo = ({ small }: LogoProps) => {
    return (
        <Image
            src={laylaFoodLogo}
            alt="LAYLA_FOOD_LOGO"
            maw={small ? 350 : 500}
            mx="auto"
        />
    );
};

export default Logo;
