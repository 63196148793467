import { MantineProvider } from '@mantine/core';
import { Suspense } from 'react';
import DigitalClock from './components/DigitalClock';
import LoadingScreen from './components/LoadingScreen';
import CustomFonts from './fonts/CustomFonts';
import AppRoutes from './routes/AppRoutes';
import darkTheme from './theme/dark';

const App = () => {
    return (
        <MantineProvider withGlobalStyles withNormalizeCSS theme={darkTheme}>
            <CustomFonts />
            <DigitalClock />
            <Suspense fallback={<LoadingScreen />}>
                <AppRoutes />
            </Suspense>
        </MantineProvider>
    );
};

export default App;
