import { Center, Container } from '@mantine/core';
import Logo from './Logo';

const LoadingScreen = () => {
    return (
        <Container
            sx={{
                height: '100%',
            }}
        >
            <Center
                sx={{
                    height: '100%',
                }}
            >
                <Logo />
            </Center>
        </Container>
    );
};

export default LoadingScreen;
