import { MantineThemeOverride, rem } from '@mantine/core';

const baseTheme: MantineThemeOverride = {
    colorScheme: 'dark',

    black: '#212025',
    white: '#fff',

    headings: {
        fontFamily: 'AcuminCondensed',
        sizes: {
            h1: {
                fontSize: rem(56),
                fontWeight: 700,
                lineHeight: 1.2,
            },
            h2: {
                fontSize: rem(48),
                fontWeight: 700,
                lineHeight: 1.2,
            },
        },
    },
};

export default baseTheme;
