import { Box, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const DigitalClock = () => {
    const [currentTime, setCurrentTime] = useState(new Date());
    const location = useLocation();

    useEffect(() => {
        const updateCurrentTime = () => {
            setCurrentTime(new Date());
        };
        const intervalId = setInterval(updateCurrentTime, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const formatTime = (time: Date) => {
        return time.toLocaleTimeString('de-DE', { hour12: false });
    };

    return (
        <>
            {location.pathname.includes('/tv/4') && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        fontWeight: 'bold',
                        backgroundColor: '#004C1B',
                        padding: '10px 16px 4px 16px',
                        color: '#fff',
                        borderBottomLeftRadius: '8px',
                    }}
                >
                    <Title order={1}>{formatTime(currentTime)}</Title>
                </Box>
            )}
        </>
    );
};

export default DigitalClock;
