import { Global } from '@mantine/core';
import AcuminCondensedThin from './acumin-pro-extra-cond-thin.ttf';
import AcuminCondensed from './acumin-pro-extra-condensed.ttf';
import AcuminPro from './acumin-pro.ttf';
import CortadoRegular from './cortado-regular.ttf';

const CustomFonts = () => {
    return (
        <Global
            styles={[
                {
                    '@font-face': {
                        fontFamily: 'Cortado',
                        src: `url('${CortadoRegular}') format("woff2")`,
                    },
                },
                {
                    '@font-face': {
                        fontFamily: 'AcuminPro',
                        src: `url('${AcuminPro}') format("woff2")`,
                    },
                },
                {
                    '@font-face': {
                        fontFamily: 'AcuminCondensed',
                        src: `url('${AcuminCondensed}') format("woff2")`,
                    },
                },
                {
                    '@font-face': {
                        fontFamily: 'AcuminCondensedThin',
                        src: `url('${AcuminCondensedThin}') format("woff2")`,
                    },
                },
            ]}
        />
    );
};

export default CustomFonts;
